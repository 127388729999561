<template>
  <div class="main">
    <div class="center">
      <el-form class="body"  @submit.native.prevent>
        <div class="title">登录账号</div>
        <el-input class="input" clearable v-model="form.username" placeholder="请输入手机号"></el-input>
        <el-input class="input" clearable v-model="form.password" type="password" placeholder="请输入密码"></el-input>
        <div class="btns">
         <el-button class="btn login" native-type="submit" @click="login" :loading="loginLoading">登录</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {LoginRequest} from '@/api'
import {md5} from '@/util/crypto'
export default {
  data () {
    return {
      form: {
        username: '',
        password: ''
      },
      loginLoading: false
    }
  },
  methods: {
    gotopage (page) {
      this.$router.replace({path: page})
    },
    login() {
      this.loginLoading = true
      setTimeout(() => {
        let params = Object.assign({}, this.form)
        params.password = md5(params.password)
        LoginRequest(params).then(res => {
          console.log(res)
          this.loginLoading = false
          if (res.code === 20000) {
            let token = res.data.token
            let user = res.data.user
            sessionStorage.setItem('token', token)
            sessionStorage.setItem('userid', user.id)
            this.$setCache('config-user', {
              token: token, 
              userid: user.id,
              username: user.username, 
              personName: user.nickname,
              role: user.role
            })
            if (user.role === 'admin') {
              // 管理员
               this.gotopage('/admin')
               return
            }
           let page =  sessionStorage.getItem('history-page')
            if (page && ['/check', '/buy'].indexOf(page) !== -1) {
               this.gotopage(page)
            } else {
              this.gotopage('/')
            }
          }
        })
      }, 500)
      
      // if (this.form.username === 'admin') {
      //    this.$setCache('config-user', {
      //       token: '11111', 
      //       personName: 'admin',
      //       role: '管理员'
      //     })
      //   this.gotopage('/admin')
      // } else {
      //   this.gotopage('/')
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
$width: calc(100% - 40px);
$height: 45px;

.main {
  display: flex;
  width: 100%;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 8px;
  background-color: #ccc;
  .center {
    background-color: #eee;
    border-radius: 5px;
    position: relative;
    padding-bottom: 400px;
    width: 90%;
    max-width: 400px;
    z-index: 1;
    
    .body {
      position: absolute;
      left: 0px;
      right: 0px;
      bottom: 0px;
      top:0px;
       display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
     
      .title {
        color: #FBD525;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333;
        text-shadow: 1px 1px 0px rgba(150, 123, 0, 0.78);
         width: $width;
         text-align: left;
         margin-bottom: 10px;
      }
      ::v-deep {
        .el-input__inner {
           height: $height;
           font-size: 16px;
        }
        .el-button+.el-button  {
          margin: 0px;
        }
      }
      .input {
        width: $width;
        margin-top: 10px;
      }
      .forget {
         width: $width;
         text-align: right;
         color: #fff;
         margin-top: 5px;
         span {
         cursor: pointer;
         }
      }
      .btns {
        width: $width;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 20px;
        .btn {
          width: 100%;
          height: $height;
          margin-top: 10px;
          font-size: 18px;
          font-weight: 400;
          text-shadow: 1px 1px 0px rgba(101, 83, 0, 0.55);
        }
        .login {
          background-color: #FBD525;
          border: none;
          color: #fff;
          &:hover {
            background-color: rgba($color: #FBD525, $alpha: 0.9);
          }
        }
        .regist {
          background-color: transparent;
          border: 2px solid #FBD525;
          color: #fff;
          &:hover {
            background-color: rgba($color: #FBD525, $alpha: 0.9);
          }
        }
      }
      
    }
  }
  .topleft {
    position: absolute;
    left: 17%;
    top:0px;
    width: 35vh;
    height: 14vh;;
     background-size: 100% 100%;
     background-image: url("../../assets/img/index/up-l.png");
  }
  .topright {
    position: absolute;
    right: 0px;
    top:0px;
    width: 23vh;
    height: 12vh;;
     background-size: 100% 100%;
     background-image: url("../../assets/img/index/up-r.png");
  }
  .bottomleft {
    position: absolute;
    left: 0px;
    bottom:7vh;
    width: 26vh;
    height: 33vh;;
     background-size: 100% 100%;
     background-image: url("../../assets/img/index/L.png");
  }
  .bottomright {
    position: absolute;
    right: 0px;
    bottom:0px;
    width: 50vh;
    height: 17vh;;
     background-size: 100% 100%;
     background-image: url("../../assets/img/index/R.png");
  }
  .round-left{
    position: absolute;
    left: 17%;
    bottom: 0px;
    width: 60px;
    height: 60px;
     background-size: 100% 100%;
     background-image: url("../../assets/img/index/xiaoqiu.png");
  }
   .round-right{
    position: absolute;
    right: 160px;
    top: 220px;
    width: 60px;
    height: 60px;
     background-size: 100% 100%;
     background-image: url("../../assets/img/index/xiaoqiu.png");
  }
}
</style>